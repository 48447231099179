import './invoice.css'
import React from 'react'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
// import { getAnalytics } from 'firebase/analytics'
import { getFirebaseConfig } from '../lib'
import currency from 'currency.js'
// var CurrencyJS = require('currencyjs') 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const app = initializeApp(getFirebaseConfig())

const formatCurrency = (number) => {
  if (!number) {
    return '$0.00'
  }
  return currency(number).format()
}

const Payments = (props) => {
  const {
    payments,
    orderTotal,
    totalTaxAmt,
    total_payments = 0,
    hazWasteAmt,
    shopSuppliesAmt,
    partsSale,
    laborSale,
    recommendations,
  } = props
  // const total = dinero({ amount: Math.round(orderTotal * 100), currency: 'USD', precision: 2 })
  // const payments = dinero({ amount: Math.round(total_payments * 100), currency: 'USD', precision: 2 })
  // const balance = total.subtract(payments)
  // const returnBalance = balance.toRoundedUnit(2)
  const recTable = recommendations.map((row, index) => {
    return (
      <div key={index} className="row border-bottom">
        <div className="col text-start">
          <div>{row.recommendDate}</div>
        </div>
        <div className="col text-start">
          <div>{row.description}</div>
        </div>
        <div className="col text-start">
          <div>{row.recommendText}</div>
        </div>
      </div>
    )
  })
  console.log('payments: ', payments)
  const payTable = payments.map((row, index) => {
    return (
      <div key={index} className="row border-bottom">
        <div className="col text-start">
          <div>{row.name || row.firstName + ' ' + row.lastName}</div>
        </div>
        <div className="col text-start">
          <div>{formatCurrency(row.amountPaid)}</div>
        </div>
        <div className="col text-start">
          <div>{row.paymentType}</div>
        </div>
        <div className="col text-start">
          <div>{row.authCode}</div>
        </div>
        <div className="col text-start">
          <div>{row.avsResponse}</div>
        </div>
        <div className="col text-start">
          <div>{row.cardType}</div>
        </div>
        <div className="col text-start">
          <div>{row.last4}</div>
        </div>
      </div>
    )
  })
  return (
    <div>
      <div className="row mt-2">
        <div className="col text-start">
          <div className="bg-primary">
            <div className="ms-2 text-start"><b>Payments</b></div>
          </div>
          <div className="ms-2 mt-2">
            <div className="row border-bottom">
              <div className="col text-start">
                <div><b>Name</b></div>
              </div>
              <div className="col text-start">
                <div><b>Amount</b></div>
              </div>
              <div className="col text-start">
                <div><b>Payment Type</b></div>
              </div>
              <div className="col text-start">
                <div><b>Auth Code</b></div>
              </div>
              <div className="col text-start">
                <div><b>AVS Response</b></div>
              </div>
              <div className="col text-start">
                <div><b>Card Type</b></div>
              </div>
              <div className="col text-start">
                <div><b>Last4</b></div>
              </div>
            </div>
          {payTable}
          </div>
          <div className="mt-2 bg-warning">
            <div className="ms-2 text-start"><b>Recommended Services</b></div>
          </div>
          <div className="ms-2 mt-2">
            <div className="row border-bottom">
              <div className="col text-start">
                <div><b>Date</b></div>
              </div>
              <div className="col text-start">
                <div><b>Type</b></div>
              </div>
              <div className="col text-start">
                <div><b>Description</b></div>
              </div>
            </div>
            {recTable}
          </div>
        </div>
        <div className="col-3 border rounded">
          <div className="row mt-2 ms-1 me-1 border-bottom">
            <div className="col text-start">Parts Total:</div>
            <div className="col text-end ">{formatCurrency(partsSale)}</div>
          </div>
          <div className="row ms-1 me-1 border-bottom">
            <div className="col text-start">Labor Total:</div>
            <div className="col text-end">{formatCurrency(laborSale)}</div>
          </div>
          { shopSuppliesAmt ? (
            <div className="row ms-1 me-1 border-bottom">
              <div className="col text-start">Shop Supplies:</div>
              <div className="col text-end">{formatCurrency(shopSuppliesAmt)}</div>
            </div>
          ): ''}
          { hazWasteAmt ? (
            <div className="row ms-1 me-1 border-bottom">
              <div className="col text-start">Hazardous Materials:</div>
              <div className="col text-end">{formatCurrency(hazWasteAmt)}</div>
            </div>
          ): ''}
          <div className="row ms-1 me-1 border-bottom">
            <div className="col text-start">Tax Total:</div>
            <div className="col text-end">{formatCurrency(totalTaxAmt)}</div>
          </div>
          <div className="row ms-1 me-1 border-bottom">
            <div className="col text-start">Sale Total:</div>
            <div className="col text-end">{formatCurrency(orderTotal)}</div>
          </div>
          <div className="row ms-1 me-1 border-bottom">
            <div className="col text-start">Surcharge:</div>
            <div className="col text-end">{formatCurrency(13.91)}</div>
          </div>
          <div className="row ms-1 me-1 border-bottom">
            <div className="col text-start">Total:</div>
            <div className="col text-end">{formatCurrency(477.41)}</div>
          </div>
          <div className="row ms-1 me-1 border-bottom">
            <div className="col text-start">Total Payments:</div>
            <div className="col text-end">{formatCurrency(total_payments)}</div>
          </div>
          <div className="row ms-1 me-1">
            <div className="col text-start">Remaining Balance:</div>
            <div className="col text-end">{currency(477.41).subtract(total_payments).format()}</div>
          </div>
        </div>
      </div>
      
    </div>
  )
}
const Labor = (props) => {
  const {
    labor
  } = props
  const laborList = labor.map((row, index) => {
    return (
      <div key={index} className="row border-bottom">
        <div className="col-2 text-start">
          <div>{row.description}</div>
          { row.symptom && (
            <div className="ms-2">Symptom: {row.symptom}</div>
          )}
        </div>
        <div className="col-8 text-start">
          <div>{row.workPerformed}</div>
        </div>
        <div className="col text-start">
          <div>{formatCurrency(row.sale)}</div>
        </div>
      </div>
    )
  })
  const totals = labor.reduce((retObj, row) => {
    retObj.sale = (retObj.sale || 0) + (row.sale || 0)
    return retObj
  }, {})
  return (
    <div>
      <div className="mt-2 bg-info redBar">
        <div className="ms-2 text-start"><b>Labor</b></div>
      </div>
      <div className="ms-2 mt-2">
        <div className="row border-bottom">
          <div className="col-2 text-start">
            <div><b>Description</b></div>
          </div>
          <div className="col-8 text-start">
            <div><b>Work Performed</b></div>
          </div>
          <div className="col text-start">
            <div><b>Extended</b></div>
          </div>
        </div>
        {laborList}
      </div>
      <div className="mt-2 row">
        <div className="col text-end me-5">
          <div><b>Subtotal:</b>{formatCurrency(totals.sale)}</div>
        </div>
      </div>
    </div>
  )
}

const Parts = (props) => {
  const {
    parts
  } = props
  const partsTable = parts.map((row, index) => {
    return (
      <div key={index} className="row border-bottom">
        <div className="col text-start">
          <div>{row.partNo}</div>
        </div>
        <div className="col text-start">
          <div>{row.description}</div>
        </div>
        <div className="col text-start">
          <div>{row.quantity}</div>
        </div>
        <div className="col text-start">
          <div>{formatCurrency(row.unitPrice)}</div>
        </div>
        <div className="col text-start">
          <div>{formatCurrency(row.unitSale)}</div>
        </div>
        <div className="col text-start">
          <div>{formatCurrency(row.sale)}</div>
        </div>
      </div>
    )
  })
  const totals = parts.reduce((retObj, row) => {
    retObj.sale = (retObj.sale || 0) + (row.sale || 0)
    return retObj
  }, {})
  return (
    <div>
      <div className="mt-2 bg-info redBar">
        <div className="text-start ms-2"><b>Parts</b></div>
      </div>
      <div className="ms-2 mt-2">
        <div className="row border-bottom">
          <div className="col text-start">
            <div><b>Part Number</b></div>
          </div>
          <div className="col text-start">
            <div><b>Description</b></div>
          </div>
          <div className="col text-start">
            <div><b>Quantity</b></div>
          </div>
          <div className="col text-start">
            <div><b>List</b></div>
          </div>
          <div className="col text-start">
            <div><b>Sale</b></div>
          </div>
          <div className="col text-start">
            <div><b>Extended</b></div>
          </div>
        </div>
        {partsTable}
      </div>
      <div className="mt-2 row">
        <div className="col text-end me-5">
          <div><b>Subtotal:</b>{formatCurrency(totals.sale)}</div>
        </div>
      </div>
    </div>
)}
const Header = (props) => {
  const {
    accountName,
    accountPhone,
    accountEmail,
    accountStreet,
    accountCity,
    accountState,
    accountZip,
    accountImage,
    customerName,
    customerStreet,
    customerCity,
    customerState,
    customerZip,
    customerPhone,
    make,
    model,
    odometerIn,
    odometerOut,
    timeIn,
    year,
    vin,
    license,
    licenseState,
    displacementLiters,
    numberOfCylinders,
    displacementCID,
    vinCode,
    invoiceNumber,
  } = props
  const today = new Date().toLocaleDateString()
  return (
    <div className="header">
      <div className="row">
        <div className="col">
        { accountImage && (
            <img src={accountImage} alt="image" className="img-fluid rounded mt-1"/>
          )}
        </div>
        <div className="col">
          <h4><b>{accountName}</b></h4>
          <h6>{accountStreet}</h6>
          <h6>{accountCity}, {accountState} {accountZip}</h6>
          <h6>{accountPhone}</h6>
          <h6>{accountEmail}</h6>
        </div>
        <div className="col">
          <h6>{today}</h6>
          <div className="row justify-content-md-center">
            <div className="col-md-auto border rounded">
              <h6>Invoice #</h6>
              <h6>{invoiceNumber}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col text-start">
          <h6 className="text-capitalize"><b>{customerName}</b></h6>
          <div>{customerStreet}</div>
          <div>{customerCity}, {customerState}, {customerZip}</div>
          <div>Phone: {customerPhone}</div>
        </div>
        <div className="col text-start">
          <h6><b>{year} {make} {model}</b></h6>
          <div className="row">
            <div className="col">
              <div>Odometer In: {odometerIn}</div>
            </div>
            <div className="col">
              <div>Odometer Out: {odometerOut}</div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div>License: {license} {licenseState}</div>
            </div>
            <div className="col">
              <div>VIN: {vin}</div>
            </div>
          </div>
          <div className="row ">
            <div className="col">
            </div>
            <div className="col">
              <div>Eng: {displacementLiters && Math.round(displacementLiters * 100) / 100}L, V{numberOfCylinders} ({displacementCID}CI) VIN({vinCode})</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 border-bottom border-dark">
        <div className="col text-start">
          <h6><b>INVOICE</b></h6>
        </div>
      </div>
      {/* <hr/> */}
      <div className="row border-bottom border-dark">
        <div className="col text-start">
          <div>Vehicle Time In: {timeIn}</div>
        </div>
      </div>
      {/* <hr/> */}
    </div>
  )
}
class Invoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.setState = this.setState.bind(this)
  }

  async componentDidMount() {
    const functions = getFunctions(app)
    // const getTokenizationKey = httpsCallable(functions, 'getTokenizationKey')
    const invoiceData = httpsCallable(functions, 'onCall-invoiceData')
    // this is a public key
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const {
        mId: merchantId,
        orderId: repairOrderId,
        date: datetime,
        exp: expire,
        sig: signature,
      } = Object.fromEntries(urlSearchParams.entries())
      //  https://asteroid-321215.web.app/invoice?mId=${merchantId}&orderId=${repairOrderId}&date=${datetime}&exp=${expire}&sig=${signature}
      // accountId, merchantToken, repairOrderId, datetime, expire, signature
      const result = await invoiceData({ merchantId, repairOrderId, datetime, expire, signature })
      const {
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountWebsite,
        accountImage,
        repairDisclaimer,
        customerName,
        customerStreet,
        customerCity,
        customerState,
        customerZip,
        customerPhone,
        orderTotal,
        totalTaxAmt,
        total_payments,
        hazWasteAmt,
        shopSuppliesAmt,
        partsSale,
        laborSale,
        make,
        model,
        odometerIn,
        odometerOut,
        timeIn,
        timeOut,
        year,
        vin,
        license,
        licenseState,
        mfgDate,
        inspDate,
        displacementLiters,
        numberOfCylinders,
        displacementCID,
        vinCode,
        recommendations,
        invoiceNumber,
        lineItems,
        payments,
        invoiceSignature,
        signatureDate,
      } = result.data
      console.log(result.data)
      this.setState({
        merchantId,
        repairOrderId,
        datetime, 
        expire, 
        signature,
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountWebsite,
        accountImage,
        repairDisclaimer,
        customerName,
        customerStreet,
        customerCity,
        customerState,
        customerZip,
        customerPhone,
        orderTotal,
        totalTaxAmt,
        total_payments,
        hazWasteAmt,
        shopSuppliesAmt,
        partsSale,
        laborSale,
        make,
        model,
        odometerIn,
        odometerOut,
        timeIn,
        timeOut,
        year,
        vin,
        license,
        licenseState,
        mfgDate,
        inspDate,
        displacementLiters,
        numberOfCylinders,
        displacementCID,
        vinCode,
        recommendations,
        invoiceNumber,
        lineItems,
        payments,
        invoiceSignature,
        signatureDate,
      })
    } catch (err) {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger' })
    }
  }

  render() {
    const {
      merchantId,
      // repairOrderId,
      // datetime, 
      // expire, 
      // signature,
      accountWebsite,
      repairDisclaimer,
      orderTotal,
      totalTaxAmt,
      total_payments,
      hazWasteAmt,
      shopSuppliesAmt,
      partsSale,
      laborSale,
      // timeOut,
      // mfgDate,
      // inspDate,
      alertMessage,
      alertClass,
      lineItems,
      payments,
      recommendations,
      invoiceSignature,
      signatureDate,
    } = this.state
    if (!merchantId) {
      return (
        <div className="App">
          { alertMessage ? (
          <div className={alertClass} role="alert">
            {alertMessage}
          </div>
        ) : (
          <div id="overlay">
            <div className="spinner"></div>
          </div>
        )}
        </div>
      )
    }
    const labor = lineItems.filter(row => !row.partNo)
    const parts = lineItems.filter(row => row.partNo)
      
    return (
      <div className="m-2 invoice">
        <div  className="container-fluid">
        <Header {...this.state}/>
        
        <Parts parts={parts}/>
        <Labor labor={labor}/>
        <div className="myDiv">
        <Payments 
          payments={payments} 
          orderTotal={orderTotal} 
          totalTaxAmt={totalTaxAmt} 
          total_payments={total_payments} 
          partsSale={partsSale} 
          laborSale={laborSale}
          recommendations={recommendations}
          hazWasteAmt={hazWasteAmt}
          shopSuppliesAmt={shopSuppliesAmt}
        />
        <div className="mt-4">
          <div className="border-top border-dark"></div>
          <div className="mt-4 row">
            <div className="col col-8 text-start">
              <div>If paying with a Credit Card you will be charged the payment amount plus a 3% credit card fee. This fee is not more than the cost of accepting these cards. There is no fee for debit cards</div>
            </div>
            <div className="col text-start">
              <div></div>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="col col-8 text-start">
              <div>{repairDisclaimer}</div>
            </div>
            <div className="col text-start">
              <div></div>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="col col-3">
              <div className="row h-25">
                <div className="col">
                  <div>Signature:</div>
                </div>
                <div className="col col-lg-8 border-bottom border-dark">
                  <div className="row" style={{ height: 34 }}>
                    { invoiceSignature ? (
                      <img src={invoiceSignature} alt="image" className="image-fluid mh-100"/>
                    ) : <div className="mt-4"></div>}
                  </div>
                  {/* <div className="row border-bottom border-dark"></div> */}
                </div>
              </div>
            </div>
            <div className="col col-3">
              <div className="row">
                <div className="col">
                  <div>Date:</div>
                </div>
                <div className="col col-lg-8 border-bottom border-dark">
                <div className="row">
                  {signatureDate ? <div className="mt-3">{signatureDate}</div> : <div className="mt-4"></div>}
                </div>
                {/* <div className="row col-lg-8 border-bottom border-dark">
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 row justify-content-md-center">
            
            <div className="col">
              <div>{accountWebsite}</div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    )
  }
}

export default Invoice